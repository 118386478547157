import React, { useCallback, memo, useMemo, useEffect } from "react";

import classNames from "common/class-names";
import { getYears, getISOString } from "common/calendar";

const AppYears = (props) => {
	const current = useMemo(() => new Date(props.value).getFullYear(), [props]);
	const maxDate = useMemo(() => (props.maxDate ? getISOString(props.maxDate) : null), [props.maxDate]);
	const minDate = useMemo(() => (props.minDate ? getISOString(props.minDate) : null), [props.minDate]);
	const years = useMemo(() => getYears(maxDate, minDate), [maxDate, minDate]);

	//prettier-ignore
	const onChange = useCallback((value) => {
		props.onChange(value);
	}, [props]);

	useEffect(() => {
		setTimeout(() => {
			const element = document.querySelector(".years__item--active");
			if (element) element.scrollIntoView({ behavior: "smooth" });
		}, 100);
	}, []);

	return (
		<div className="app-years">
			<ul className="years" id="years">
				{years.map((o, i) => {
					const isCurrentYear = o.thisYear;
					const isActive = o.value === current;

					const itemClassNames = classNames({
						years__item: true,
						"years__item--active": isActive,
						"years__item--current": isCurrentYear,
					});

					return (
						<li key={o.value} className={itemClassNames} onClick={() => onChange(o.value)}>
							<div className="years__year">
								<span>{o.year}</span>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default memo(AppYears);
