import React, { memo, useMemo, useState, useCallback, useRef } from "react";
import { FormControl, TextField, InputAdornment } from "@mui/material";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import classNames from "common/class-names";
import { CALENDAR_FORMAT, formatDatePattern, getISOString } from "common/calendar";
import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppCloseIcon from "components/icons/app-close-icon";
import AppCalendar from "components/app-calendar-input/app-calendar";
import lockIcon from "assets/images/components/app-input/lock-icon.svg";
import calendarIcon from "assets/images/components/app-calendar-input/calendar-icon.png";

const AppCalendarInput = (props) => {
	const TYPES = useMemo(() => ({ REMOVE: "REMOVE", ADD: "ADD" }), []);
	const today = useMemo(() => getISOString(new Date()), []);
	const [date, setDate] = useState(props.value);
	const minDate = useRef(props.minDate);
	const maxDate = useRef(props.maxDate);
	const dateFormat = useMemo(() => props.displayFormat || CALENDAR_FORMAT.DATE_FORMAT + "-" + CALENDAR_FORMAT.MONTH_NUMBER_FORMAT + "-" + CALENDAR_FORMAT.YEAR_FORMAT, [props.displayFormat]);
	const currentDate = useMemo(() => (props.value ? formatDatePattern(new Date(props.value).toDateString(), dateFormat) : ""), [dateFormat, props.value]);
	const [visible, setVisible] = useState(false);
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);

	const className = useMemo(() => {
		return classNames({
			"app-calendar-input": true,
			"app-calendar-input--disabled": props.disabled,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.disabled]);

	const onHandleSelectDate = useCallback((v) => {
		setDate(v);
	}, []);

	//prettier-ignore
	const onHandleCalendar = useCallback((event) => {
		const type = event.target.getAttribute("data-type");

		if(type === TYPES.REMOVE) { 
			event.stopPropagation();
			props.onChange(props.name, "");
		}

		if(type === TYPES.ADD) {
			if (props.disabled) return;

			if (!props.value) setDate(today);

			setVisible(true);
		}
	}, [TYPES, props, today]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback(() => {
		props.onChange(props.name, date ? date.toString() : props.value);
		onHandleDismiss();
	}, [props, date, onHandleDismiss]);

	const InputProps = useMemo(() => {
		const inputProps = {};

		const endAdornment = {};

		if (props.disabled) {
			endAdornment.endAdornment = (
				<InputAdornment position="end">
					<AppIcon src={lockIcon} />
				</InputAdornment>
			);
		} else if (props.value) {
			endAdornment.endAdornment = (
				<InputAdornment position="end" data-type={TYPES.REMOVE}>
					<AppCloseIcon className="app-calendar-input__icon" data-type={TYPES.REMOVE} color="#F64444" onClick={onHandleCalendar} />
				</InputAdornment>
			);
		} else {
			endAdornment.endAdornment = (
				<InputAdornment position="end">
					<AppIcon src={calendarIcon} />
				</InputAdornment>
			);
		}

		Object.assign(inputProps, endAdornment);

		return inputProps;
	}, [TYPES, props.disabled, props.value, onHandleCalendar]);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				<label className="app-calendar-input__label" htmlFor={props.name}>
					{props.label}
					{props.required && <span className="app-calendar-input__required">*</span>}
				</label>

				<div className="app-calendar-input__wrapper" data-type={TYPES.ADD} onClick={onHandleCalendar}>
					<TextField autoComplete="off" value={currentDate} type="text" name={props.name} error={isErrorField} helperText={errorMessage} disabled placeholder={props.placeholder} InputProps={InputProps} />
				</div>
			</FormControl>

			<Modal classes={{ root: "app-calendar-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="calendar-modal" aria-describedby="calendar-select-modal">
				<div className="calendar-modal">
					<AppCalendar value={props.value} minDate={minDate.current} maxDate={maxDate.current} onHandleSelectDate={onHandleSelectDate} />

					<div className="calendar-modal__button-container">
						<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
						<AppButton type="button" label="Ok" onClick={onHandleSubmit} />
					</div>
				</div>
			</Modal>
		</div>
	);
};

AppCalendarInput.propTypes = {
	error: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default memo(AppCalendarInput);
