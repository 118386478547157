const COMMON = {
	REDUX_ACTION: {
		LOGOUT: "LOGOUT",
	},
	EVENTS: {
		APP: "APP",
		LOADED: "LOADED",
		SUCCESS: "SUCCESS",
	},
	REDUX_PRESIST_KEY: "root_express_motor_6a46285d-e323-48ae-8a84-e1bc036f0223",
	AUTH_TOKEN: "easy_cover_express_motor_@99e53932-f4d8-46fc-9bba-757fd5e32783_auth_tk",
	REFRESH_TOKEN: "easy_cover_express_motor_@273f71f5-0d2d-45cd-b93b-fee2c84aea7b_refresh_tk",
	ENDPOINT_PATH: {
		INSURER: {
			QUOTATION: "motor/public/insurance/motor/getQuotation",
			UPDATE_QUOTATION: "motor/public/insurance/motor/updateQuotation",
			PAYMENT_CHECKOUT: "payment/public/motor/payment/checkout",
			PAYMENT_STATUS: "payment/public/motor/payment/paymentDetails",
			CONFIRM_QUOTATION: "motor/public/insurance/motor/confirmQuotation",
			RECALCULATE_QUOTATION: "motor/public/insurance/motor/recalculateQuotation",
			REGENERATE_QUOTATION: "motor/public/insurance/motor/regenerateQuotation"
		},
		GENERAL: {
			LIST: "general/public/lookup/detail",
			CITIES: "general/public/address/city/page",
			STATES: "general/public/address/state/page",
			POSTCODE: "general/public/address/postcode/",
		},
	},
	REGEX: {
		SYMBOL: /(\W|_)/,
		NUMERIC: /(?=.*\d)/,
		LOWERCASE: /(?=.*[a-z])/,
		UPPERCASE: /(?=.*[A-Z])/,
		EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		HUNDRED_VALIDATION: /^(\d+,?([0]{2})|\d+,?([0]{2})\.[0]{1,2})$/,
	},
	MASKING: {
		NRIC: "******-**-****",
	},
	IDENTIFICATION_TYPE: {
		NRIC: "NRIC",
		PASSPORT: "PASSPORT",
	},
	DROPDOWNS: {
		SORT: [
			{ label: "Low to High", value: "Low to High" },
			{ label: "High to Low", value: "High to Low" },
		],
		GENDER: [
			{ label: "Female", value: "FEMALE" },
			{ label: "Male", value: "MALE" },
		],
	},
	NUMBERS: ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Nineth", "Tenth"],
	MAX_FILE_SIZE: 5000000,
	MESSAGE_TYPE: {
		RENEW: "RENEW",
		INVALID: "INVALID",
		NETWORK: "NETWORK",
		PAYMENT_FAILED: "PAYMENT_FAILED",
		SUCCEEDED: "SUCCEEDED",
		FAILED: "FAILED",
		APPLICANT_FAILED: "APPLICANT_FAILED",
	},
	ERRORS: {
		GENERIC: "HS_00000001",
		POLICY_NOT_EXPIRED: "VI_00000001",
		VIX_CALL_FAILED: "VI_00000002",
		VEHICLE_STATUS_FAILED: "VI_00000003",
		QUOTATION_FAILED_RETRIEVE: "VI_00000004",
		QUOTATION_CALL_FAILED: "VI_00000005",
		POSTCODE_NOT_FOUND: "VI_00000006",
		QUOTATION_NOT_FOUND: "Q_0000001",
		POLICY_EXPIRED_LONG_PERIOD: "VI_00000007",
		NO_QUOTATION: "Q_0000002",
		COVER_NOTE_FAILED_RETRIEVE: "C_0000001",
		PAYMENT_QUOTATION_INVALID: "P_0000001",
		PAYMENT_EXIST: "P_0000002",
		BE_BAD_RESPONSE: "ERR_BAD_RESPONSE",
		NETWORK_ERROR: "ERR_NETWORK",
	},
	EXTRA_BENEFITS: {
		DRIVERS_COVERAGE: "AD",
		ALL_DRIVER: "001",
		ADDITIONAL_DRIVERS: "002",
		PERSONAL_ACCDIDENT: "003",
		NATURAL_DISASTER: "004",
		WINDSCREEN_PROTECTION: "005",
	},
	PROVIDERS: {
		LIBERTY: "LIBERTY",
	},
};

export default COMMON;
