import React, { memo } from "react";
import PropTypes from "prop-types";

const AppCardInfo = (props) => {
	return (
		<div className="app-card-info">
			<div className="card-info">
				<p className="card-info__title">{props.data?.title}</p>

				{props.data?.info?.map((o) => (
					<ul className="list" key={o.label}>
						<li className="list__item">
							<p className="list__text">{o.label}</p>
							<p className="list__text list__text--value">{o.value}</p>
						</li>
					</ul>
				))}
			</div>
		</div>
	);
};

AppCardInfo.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string.isRequired,
		info: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			})
		),
	}),
};

export default memo(AppCardInfo);
