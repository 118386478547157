import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	enquiry: null,
	quotation: null,
	applicant: null,
	receipt: null,
	referralCode: null
};

const motorSlice = createSlice({
	name: "motor",
	initialState,
	reducers: {
		setEnquiry(state, action) {
			return Object.assign(state, { enquiry: action.payload });
		},
		setQuotation(state, action) {
			return Object.assign(state, { quotation: action.payload });
		},
		setApplicant(state, action) {
			return Object.assign(state, { applicant: action.payload });
		},
		setReferralCode(state, action) {
			return Object.assign(state, { referralCode: action.payload });
		},
		setReceipt(state, action) {
			return Object.assign(state, { receipt: action.payload });
		},
		resetMotor() {
			return initialState;
		},
	},
});

export const { setEnquiry, setQuotation, setApplicant, setReceipt, setReferralCode, resetMotor } = motorSlice.actions;

export default motorSlice.reducer;
