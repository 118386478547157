import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import AppButton from "components/app-button";
import removeIcon from "assets/images/remove-icon.svg";
import arrowUpIcon from "assets/images/pages/page-summary/arrow-up-icon.svg";

const AppAccordionContainer = (props) => {
	return (
		<div className="app-accordion-container app-accordion-container--active">
			<div className="main">
				<div className="main__header">
					<div className="main__wrapper">
						<p className="main__title">{props.title}</p>
						<p className="main__description">{props.description}</p>
					</div>

					<div className="main__wrapper">
						<Box className={"main__arrow main__arrow--expand"} component="img" src={arrowUpIcon} onClick={props.onHandleChangeExpand} />
					</div>
				</div>

				<div className="main__divider" />

				<div className="main__body">
					<div className="main__box">{props.children}</div>
				</div>

				<div className="main__divider" />

				{props.onHandleRemove && props.length !== 1 && (
					<div className="main__footer">
						<div className="main__button-wrapper">
							<AppButton type="button" outline label="Delete Driver" icon={removeIcon} onClick={props.onHandleRemove} />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

AppAccordionContainer.propTypes = {
	title: PropTypes.string,
	length: PropTypes.number,
	description: PropTypes.string,
	onHandleRemove: PropTypes.func,
	children: PropTypes.node.isRequired,
	onHandleChangeExpand: PropTypes.func,
};

export default memo(AppAccordionContainer);
