import React, { memo, useRef, useCallback, useState, useEffect, useMemo, useImperativeHandle, forwardRef } from "react";
import { FormControl, ListSubheader, TextField, Select, MenuItem, FormHelperText, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import AppIcon from "components/app-icon";
import classNames from "common/class-names";
import searchIcon from "assets/images/search-icon.svg";
import AppChevronIcon from "components/icons/app-chevron-icon";
import lockIcon from "assets/images/components/app-input/lock-icon.svg";

const AppSelectInput = (props, ref) => {
	const pagination = useRef();
	const searchInputRef = useRef();
	const extraOptionsData = useRef();
	const optionsInitialized = useRef(false);
	const loadOptionsReinitalize = useRef(false);
	const [fetching, setFetching] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [fetchingError, setFetchingError] = useState("");
	const [selectOptions, setSelectOptions] = useState([]);
	const loadOptionsFunction = useMemo(() => props.loadOptions, [props.loadOptions]);
	const searchable = useMemo(() => ("searchable" in props ? props.searchable : true), [props]);
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);
	const optionsData = useMemo(() => {
		if (selectOptions.length) return selectOptions;
		else if (props.options && !searchValue) return props.options;
		else return [];
	}, [selectOptions, props.options, searchValue]);
	const selectValue = useMemo(() => (optionsData?.length ? props.value : ""), [optionsData, props.value]);

	const className = useMemo(() => {
		return classNames({
			"app-select-input": true,
			"app-select-input--placeholder": !props.value,
			"app-select-input--disabled": props.disabled,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.value, props.disabled]);

	//prettier-ignore
	const SelectIcon = useCallback((res) => {
		if (props.disabled) return <InputAdornment position="end"><AppIcon src={lockIcon} /></InputAdornment>

		return <div className="app-select-input__icon MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined"><AppChevronIcon {...res} color="#677686" /></div>
	}, [props.disabled]);

	//prettier-ignore
	const onHandleLoadOptions = useCallback(async (payload) => {
		if (!loadOptionsFunction) return;

		setFetching(true);
		setFetchingError("");

		optionsInitialized.current = true;
		loadOptionsReinitalize.current = false;

		try {
			const response = await loadOptionsFunction(payload);

			setFetchingError("");

			if (payload?.page >= 1) {
				setSelectOptions((prev) => [...prev, ...response.content]);
			}
			else {
				setSelectOptions(response.content);
				extraOptionsData.current = response.content;
			}

			pagination.current = { ...pagination.current, total: response.totalPages, page: response.number + 1 };
		} catch (fetchError) {
			setFetchingError(fetchError);
		} finally {
			setFetching(false);
		}

	}, [loadOptionsFunction]);

	const onHandleScroll = (event) => {
		if (!props.pagination) return;

		const maxHeight = 300;
		const page = pagination.current.page;
		const total = pagination.current.total;
		const keyword = pagination.current.keyword;
		const scrollHeight = event.target.scrollHeight;
		const scrollTop = event.target.scrollTop + maxHeight;

		if (scrollTop === scrollHeight && total > page) {
			const payload = { page, keyword };
			onHandleLoadOptions(payload);
		}
	};

	//prettier-ignore
	const onHandleSearch = (event) => {
		const value = event.target.value;

		if (props.pagination) {
			pagination.current.keyword = value;
			const payload = { page: 0, keyword: value };
			onHandleLoadOptions(payload);
		} else {

			if (!extraOptionsData.current) extraOptionsData.current = optionsData;

			const filtered = extraOptionsData.current.slice(0).filter((o) => {
				const currentValues = value.toLowerCase();
				const label = o.label?.toLowerCase();
				const includedLabel = label.includes(currentValues);
				return includedLabel;
			});

			setSearchValue(value);
			setSelectOptions(filtered);

			if(!filtered.length) {
				setFetchingError("No Data");
			}
			else {
				setFetchingError("");
			}
		}
	};

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const SearchInput = useCallback((obj) => {
		const defaultValue = pagination?.current?.keyword || obj?.searchValue;

		const inputProps = {
			endAdornment: (
				<InputAdornment position="end">
					<AppIcon src={searchIcon} />
				</InputAdornment>
			),
		};

		return (
			<ListSubheader>
				<TextField inputRef={searchInputRef} name="keyword" type="text" placeholder="Search..." defaultValue={defaultValue} InputProps={inputProps} onChange={obj?.onHandleDebounceSearch} onKeyDown={(event) => event.stopPropagation()} />
			</ListSubheader>
		);
	}, []);

	const onHandleFocus = useCallback(() => {
		const notInSearchMode = !pagination.current?.keyword;
		if ((!optionsData?.length && notInSearchMode) || loadOptionsReinitalize.current) onHandleLoadOptions();
	}, [onHandleLoadOptions, optionsData]);

	useEffect(() => {
		if (props.value && !optionsData.length && !optionsInitialized.current) onHandleLoadOptions();
	}, [props.value, onHandleLoadOptions, optionsData]);

	useEffect(() => {
		if (onHandleLoadOptions) loadOptionsReinitalize.current = true;
	}, [onHandleLoadOptions]);

	useImperativeHandle(ref, () => ({
		getOptionsData: () => optionsData,
	}));

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				<label className="app-select-input__label" htmlFor={props.name}>
					{props.label}
					{props.required && <span className="app-select-input__required">*</span>}
				</label>
				{/* prettier-ignore */}
				<Select displayEmpty
					name={props.name}
					value={selectValue}
					onOpen={onHandleFocus}
					onChange={props.onChange}
					disabled={props.disabled}
					IconComponent={SelectIcon}
					MenuProps={{ classes: { root: "app-select-input-modal" }, PaperProps: { onScroll: onHandleScroll } }}>

					<MenuItem value="" disabled>{fetching ? "Loading..." : props.placeholder}</MenuItem>

					{props.defaultOptions && <MenuItem classes={{ root: "app-select-input-modal__hidden" }} value={props.defaultOptions.value.toString()} disabled>{props.defaultOptions.label}</MenuItem>}

					{searchable && <SearchInput searchValue={searchValue} onHandleDebounceSearch={onHandleDebounceSearch} />}

					{fetchingError ? (<MenuItem value="errors" disabled>{fetchingError}</MenuItem>) : null}

					{optionsData.map((o, i) => <MenuItem key={i} value={o.value}>{o.label}</MenuItem>)}
				</Select>

				<FormHelperText>{errorMessage}</FormHelperText>
			</FormControl>
		</div>
	);
};

export default memo(forwardRef(AppSelectInput));

AppSelectInput.propTypes = {
	error: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	pagination: PropTypes.bool,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	defaultOptions: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}).isRequired
	),
	searchable: PropTypes.bool,
};
