import React, { forwardRef, useImperativeHandle, memo, useState, useCallback, useMemo } from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import formatCurrencyPattern from "common/format-currency-pattern";
import getVehicleBodyListing from "services/get-vehicleBody-listing";

const AppRegenerateModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [regenerateForm, setRegenerateForm] = useState({ sumInsured: "", nvicCode: "", vehicleBody: "" });
	const nvicOptions = useMemo(() => props?.nvicDropdown?.map((o) => ({ label: o.nvic, value: o.nvic, ...o })), [props?.nvicDropdown]);

	//prettier-ignore
	const initialValues = useMemo(() => {

	let payload = {
		sumInsured: "",
		nvicCode: "",
		vehicleBody: ""
	}

	if (regenerateForm) payload = regenerateForm

	return payload;
	}, [regenerateForm]);

	//prettier-ignore
	const formik = useFormik({
		enableReinitialize: true,
		validateOnBlur: true,
		initialValues,
		validationSchema: yup.object({
			sumInsured: yup.string().required().test("validate sum insured range", "Enter the amount within the market value range.", function (value) {
				const current = value;	
				
				return (parseFloat(current?.replace(",", "")) >= (this.parent.minimumMarketValue|| 0) && parseFloat(current?.replace(",", "")) <= this.parent.maximumMarketValue)
			}),
		}),
		onSubmit: (values) => {
			const payload = { ...values, requireRegenerate: true };
			props.onHandleSubmit(payload);
			formik.setSubmitting(false);
		},
	});

	//prettier-ignore
	const onHandleUpdate = useCallback((event, nvicList) => {
		const selectedNvic = nvicList?.find((o) => o.nvic === event.target.value);

		formik.setFieldValue("nvicCode", event.target.value);

		formik.setFieldValue("minimumMarketValue", selectedNvic.minMarketValue);

		formik.setFieldValue("maximumMarketValue", selectedNvic.maxMarketValue);

		formik.setFieldValue("modelDescription", selectedNvic.modelDescription);
	}, [formik]);

	//prettier-ignore
	const onHandleUpdateVehicleBody = useCallback((event) => {
		formik.setFieldValue("vehicleBody", event.target.value);
	}, [formik]);

	const onHandleKeyDown = (event) => {
		const cursorPosition = event.target.selectionEnd;
		const value = event.target.value;

		if (cursorPosition !== value.length) {
			event.target.selectionEnd = value.length;
			event.target.selectionStart = value.length;
			event.preventDefault();
		}

		if (event.key.length === 1 && !event.key.match(/\d/i)) {
			event.preventDefault();
		}
	};

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		const selectedNvic = props?.nvicDropdown?.find((o) => o.nvic === values?.nvicCodeDisplay);
		
		setRegenerateForm({...values, modelDescription: selectedNvic?.modelDescription});

		formik.setErrors({});
		setVisible(true);
	}, [setRegenerateForm, formik, props?.nvicDropdown]);

	//prettier-ignore
	const onHandleDismiss = useCallback((nvicList) => {
		setVisible(false);

		const selectedNvic = nvicList?.find((o) => o.nvic === formik.values?.nvicCodeDisplay);

		formik.setFieldValue("nvicCode", formik.values?.nvicCodeDisplay);

		formik.setFieldValue("sumInsured", formik.values?.sumInsuredDisplay);

		formik.setFieldValue("vehicleBody", formik.values?.vehicleBodyDisplay);

		formik.setFieldValue("minimumMarketValue", selectedNvic?.minMarketValue);

		formik.setFieldValue("maximumMarketValue", selectedNvic?.maxMarketValue);

		formik.setFieldValue("modelDescription", selectedNvic.modelDescription);
	}, [formik]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal keepMounted aria-labelledby="regenerate-modal__title" aria-describedby="regenerate-modal__text" open={visible} closeAfterTransition onClose={() => onHandleDismiss(props?.nvicDropdown)}>
			<Fade in={visible}>
				<div className="app-regenerate-modal">
					<div className="regenerate-modal">
						<div className="regenerate-modal__container">
							<form className="regenerate-modal__form" onSubmit={formik.handleSubmit}>
								{/* prettier-ignore */}
								<AppInput required type="text" name="sumInsured" label="Sum Insured (RM)" placeholder="00.00" onKeyDown={onHandleKeyDown} onFormat={formatCurrencyPattern} onChange={formik.handleChange} value={formik.values?.sumInsured} touched={formik.touched?.sumInsured} error={formik.errors?.sumInsured} disabled={formik.isSubmitting} />
								{/* prettier-ignore */}
								<AppSelectInput required type="text" name="nvicCode" label="NVIC Code" placeholder="Please Select" options={nvicOptions} value={formik.values?.nvicCode} error={formik.errors?.nvicCode} touched={formik.touched?.nvicCode} onChange={(event) => onHandleUpdate(event, props?.nvicDropdown)} disabled={formik.isSubmitting} />
								{/* prettier-ignore */}
								<AppInput type="text" name="modelDescription" label="Model" placeholder="" onChange={formik.handleChange} value={formik.values?.modelDescription} touched={formik.touched?.modelDescription} error={formik.errors?.modelDescription} disabled />
								{/* prettier-ignore */}
								<AppSelectInput required type="text" name="vehicleBody" label="Vehicle Body" placeholder="Please Select" loadOptions={getVehicleBodyListing} value={formik.values?.vehicleBody} error={formik.errors?.vehicleBody} touched={formik.touched?.vehicleBody} onChange={onHandleUpdateVehicleBody} disabled={formik.isSubmitting} />

								<div className="regenerate-modal__button-container">
									<AppButton type="button" label="Cancel" outline onClick={() => onHandleDismiss(props?.nvicDropdown)} />
									<AppButton type="submit" label="Regenerate Quotation" disabled={!formik.dirty} />
								</div>
							</form>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default memo(forwardRef(AppRegenerateModal));

AppRegenerateModal.propTypes = {
	onHandleSubmit: PropTypes.func,
	nvicDropdown: PropTypes.array,
};
