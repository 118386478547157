import React, { useMemo, useCallback, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import COMMON from "common";
import ERRORS from "common/errors";
import { CALENDAR_FORMAT } from "common/calendar";
import { getDateOfBirthNRIC } from "common/nric-helpers";
import serveRequestErrors from "common/serve-request-errors";
import formatPassportString from "common/format-passport-string";
import { getDateOfBirthAge } from "common/get-date-of-birth-age";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppButtonInput from "components/app-button-input";
import AppMobileInput from "components/app-mobile-input";
import AppMaskingInput from "components/app-masking-input";
import AppCalendarInput from "components/app-calendar-input";
import AppLoading from "components/pages/page-enquiry/app-loading";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import AppCardContainer from "components/pages/page-quotation/app-card-container";
import pathnames from "routes/pathnames";
import api from "services/api";
import getStateListing from "services/get-state-listing";
import getCitiesListing from "services/get-cities-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getOccupationListing from "services/get-occupation-listing";
import getMaritalStatusListing from "services/get-maritalStatus-listing";
import { setApplicant, setQuotation } from "store/slices/motor";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const PageApplicant = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const motor = useSelector((state) => state.motor);
	const [messageType, setMessageType] = useState("");
	const enquiryInfo = useMemo(() => motor?.enquiry, [motor?.enquiry]);
	const quotationInfo = useMemo(() => motor?.quotation, [motor?.quotation]);
	const applicantInfo = useMemo(() => motor?.applicant, [motor?.applicant]);
	const quotationCheckout = useMemo(() => quotationInfo?.quotationCheckout, [quotationInfo?.quotationCheckout]);
	const isPassport = useMemo(() => enquiryInfo?.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [enquiryInfo?.identificationType]);
	const validIdentificationType = useMemo(() => !!enquiryInfo?.identificationType, [enquiryInfo?.identificationType]);

	/* prettier-ignore */
	const initialValues = useMemo(() => {
		let payload = { 
			name: "", 
			passport: "", 
			nric: "", 
			mobile: "", 
			prefixNo: "", 
			office: "", 
			officeNo: "", 
			officePrefixNo: "", 
			maritalStatus: "", 
			gender: "", 
			dob: "",
			email: "", 
			correspondenceAddressLine1: "",
			correspondenceAddressLine2: "", 
			state: "", 
			city: "", 
			postcode:"" ,
			occupation: "", 
			experience: ""
		}

		if(!isPassport) {
			const identificationNo = enquiryInfo?.identificationNo;

			if(identificationNo) {
				const gender = parseInt(identificationNo?.split("")?.pop()) % 2 === 0 ? "FEMALE" : "MALE";

				payload.gender = gender;

				payload.dob = getDateOfBirthNRIC(identificationNo);

				payload.nric = identificationNo;
			}
			
		} else if(isPassport) {
			payload.passport = enquiryInfo?.identificationNo;
		}

		if (applicantInfo) { 
			if (applicantInfo.name) payload.name = applicantInfo.name;

			if (applicantInfo.number) {
				if(isPassport) payload.passport = applicantInfo?.number;
				
				if(!isPassport) payload.nric = applicantInfo?.number;
			}

			if (applicantInfo.mobile) payload.mobile = applicantInfo.mobile;

			if (applicantInfo.prefixNo) payload.prefixNo = applicantInfo.prefixNo;

			if (applicantInfo.office) payload.office = applicantInfo.office;

			if (applicantInfo.officePrefixNo) payload.officePrefixNo = applicantInfo.officePrefixNo;

			if (applicantInfo.maritalStatus) payload.maritalStatus = applicantInfo.maritalStatus;

			if (applicantInfo.gender) payload.gender = applicantInfo.gender;

			if (applicantInfo.dob) payload.dob = applicantInfo.dob;

			if (applicantInfo.email) payload.email = applicantInfo.email;

			if (applicantInfo.address1) payload.correspondenceAddressLine1 = applicantInfo.address1;
			
			if (applicantInfo.address2) payload.correspondenceAddressLine2 = applicantInfo.address2;
			
			if (applicantInfo.state) payload.state = applicantInfo.state;
			
			if (applicantInfo.city) payload.city = applicantInfo.city;
			
			if (applicantInfo.postcode) payload.postcode = applicantInfo.postcode;

			if (applicantInfo.occupation) payload.occupation = applicantInfo.occupation;
			
			if (applicantInfo.experience) payload.experience = applicantInfo.experience;
		};

		return payload
	},[applicantInfo, enquiryInfo, isPassport]);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			passport: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.string().required(ERRORS.REQUIRED).min(14, ERRORS.NRIC),
			}),
			dob: yup.string().required(ERRORS.REQUIRED),
			gender: yup.string().required(ERRORS.REQUIRED),
			maritalStatus: yup.string().required(ERRORS.REQUIRED),
			mobile: yup.string().required(ERRORS.REQUIRED).min(9, ERRORS.MOBILE),
			email: yup.string().required(ERRORS.REQUIRED).matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL),
			correspondenceAddressLine1: yup.string().required(ERRORS.REQUIRED),
			correspondenceAddressLine2: yup.string().required(ERRORS.REQUIRED),
			city: yup.string().required(ERRORS.REQUIRED),
			state: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.string().required(ERRORS.REQUIRED),
			occupation: yup.string().required(ERRORS.REQUIRED),
			experience: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleNavigateQuotation = () => {
		navigate(pathnames.quotation);
	};

	const onHandleNavigateSummary = () => {
		navigate(pathnames.summary);
	};

	const getCitiesLoadOptions = useCallback(() => getCitiesListing({ state: formik.values.state }), [formik.values.state]);
	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.city}`), [formik.values.state, formik.values.city]);

	const onHandleSubmit = async (values) => {
		let response = null;
		const selectedQuotation = quotationCheckout?.find((o) => {
			return o.insurer === quotationInfo?.provider;
		});

		const benefitPayload = selectedQuotation?.selectedExtraBenefit?.length ? selectedQuotation?.selectedExtraBenefit : quotationInfo?.extraBenefitList;

		const isAdditionalDriver = benefitPayload?.some((i) => i?.benefitCode === COMMON.EXTRA_BENEFITS.ADDITIONAL_DRIVERS);

		try {
			let payload = {
				identificationType: enquiryInfo?.identificationType,
				identificationNo: enquiryInfo?.identificationNo,
				quotationId: quotationInfo?.quotationId,
				nvic: quotationInfo?.selectedNvicCode,
				provider: quotationInfo?.provider,
				sumInsured: selectedQuotation?.sumInsured,
				extraBenefitList: benefitPayload,
				additionalDriverList: isAdditionalDriver ? quotationInfo?.additionalDriverList : null,
				applicantInformation: {
					name: values.name,
					email: values.email,
					nric: isPassport ? values.passport : values.nric,
					age: getDateOfBirthAge(values.dob),
					birthDate: values.dob,
					mobileNo: values.prefixNo + values.mobile,
					gender: values.gender,
					maritalStatus: values.maritalStatus,
					address1: values.correspondenceAddressLine1,
					address2: values.correspondenceAddressLine2 ?? "",
					state: values.state,
					city: values.city,
					postcode: values.postcode,
					officeNo: values.office ? values.officePrefixNo + values.office : "",
					mobile: values.mobile,
					prefixNo: values.prefixNo,
					officePrefixNo: values.officePrefixNo,
					office: values.office,
					occupation: values.occupation,
					experience: values.experience,
				},
				agreedValue: quotationInfo.agreedValue,
				vehicleBody: quotationInfo.vehicleBody,
			};

			dispatch(setApplicant(payload.applicantInformation));
			response = await api.post.motor.updateQuotation(payload);
		} catch (error) {
			switch (error?.response?.data?.errorCode) {
				case COMMON.ERRORS.GENERIC:
				case COMMON.ERRORS.NO_QUOTATION:
				case COMMON.ERRORS.QUOTATION_NOT_FOUND:
				case COMMON.ERRORS.QUOTATION_CALL_FAILED:
				case COMMON.ERRORS.QUOTATION_FAILED_RETRIEVE:
				case COMMON.ERRORS.COVER_NOTE_FAILED_RETRIEVE:
					setMessageType(COMMON.MESSAGE_TYPE.APPLICANT_FAILED);
					break;
				default:
					serveRequestErrors(error);
					break;
			}
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(setQuotation({ ...quotationInfo, ...response }));
			onHandleNavigateSummary();
		}
	};

	const IdentificationField = useCallback((obj) => {
		if (!obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppMaskingInput required type="text" name="nric" label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={true} value={obj.values.nric} error={obj.errors.nric} touched={obj.touched.nric} onChange={obj.onChange} format={COMMON.MASKING.NRIC} />
		}

		if (obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppInput required type="text" name="passport" label="Passport" placeholder="e.g. VD1289281" disabled={true} value={obj.values.passport} error={obj.errors.passport} touched={obj.touched.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}

		return null;
	}, []);

	//prettier-ignore
	const onHandleSelectField = useCallback(async (event) => {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case "state":
				formik.setFieldValue("city", "");
				formik.setFieldValue("postcode", "");
				break;
			case "city":
				formik.setFieldValue("postcode", "");
				break;
			case "postcode":
				break;
			default:
				break;
		}

		formik.setFieldValue(name, value);
	}, [formik]);

	return (
		<div className="page-applicant">
			<div className="applicant">
				<form onSubmit={formik.handleSubmit}>
					{!formik.isSubmitting && !messageType && (
						<div className="applicant__container">
							<p className="applicant__title">Applicant Information</p>

							<AppCardContainer title="Vehicle Owner Info" description="Please complete the vehicle owner details.">
								<div className="applicant__box">
									<div className="applicant__wrapper">
										<div className="applicant__box-body">
											{/* prettier-ignore */}
											<AppInput required type="text" maxLength={100} name="name" label="Name" placeholder="e.g. Sharen Wong" value={formik.values.name} touched={formik.touched.name} error={formik.errors.name} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{/* prettier-ignore */}
											<IdentificationField values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} disabled={formik.isSubmitting} isPassport={isPassport} validIdentificationType={validIdentificationType} />

											{/* prettier-ignore */}
											<AppCalendarInput required name="dob" label="Date of Birth" placeholder="e.g. 16 July 1994" maxDate={new Date()} displayFormat={calendarDisplayFormat} disabled={!isPassport} value={formik.values.dob} error={formik.errors?.dob} touched={formik.touched?.dob} onChange={formik.handleChange} />

											{/* prettier-ignore */}
											<AppInput required type="text" maxLength={100} name="email" label="Email Address" placeholder="e.g. username@email.com" value={formik.values.email} touched={formik.touched.email} error={formik.errors.email} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{/* prettier-ignore */}
											<AppMobileInput required type="number" maxLength={15} name="mobile" prefixNoName="prefixNo" label="Mobile No" placeholder="e.g. 121231234" value={formik.values.mobile} prefixNo={formik.values.prefixNo} error={formik.errors?.mobile} touched={formik.touched?.mobile} disabled={formik.isSubmitting} onChange={formik?.handleChange} onChangeCode={formik.setFieldValue} />

											{/* prettier-ignore */}
											<AppMobileInput type="number" name="office" maxLength={15} prefixNoName="officePrefixNo" label="Office No" placeholder="e.g. 79871123" value={formik.values.office} prefixNo={formik.values.officePrefixNo} error={formik.errors?.office} touched={formik.touched?.office} disabled={formik.isSubmitting} onChange={formik?.handleChange} onChangeCode={formik.setFieldValue} />

											{/* prettier-ignore */}
											<AppButtonInput required name="gender" label="Gender" disabled={!isPassport} value={formik.values.gender} error={formik.errors.gender} touched={formik.touched.gender} onChange={formik.setFieldValue} options={COMMON.DROPDOWNS.GENDER} />

											{/* prettier-ignore */}
											<AppSelectInput required type="text" name="maritalStatus" label="Marital Status" placeholder="Please Select" loadOptions={getMaritalStatusListing} value={formik.values.maritalStatus} error={formik.errors.maritalStatus} touched={formik.touched.maritalStatus} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{/* prettier-ignore */}
											<AppInput required type="text" maxLength={100} name="correspondenceAddressLine1" label="Correspondence Address (Line 1)" placeholder="e.g. 123, Jalan abc/123" value={formik.values.correspondenceAddressLine1} touched={formik.touched.correspondenceAddressLine1} error={formik.errors.correspondenceAddressLine1} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{/* prettier-ignore */}
											<AppInput required type="text" maxLength={100} name="correspondenceAddressLine2" label="Correspondence Address (Line 2)" placeholder="e.g. 123, Jalan abc/123" value={formik.values.correspondenceAddressLine2} touched={formik.touched.correspondenceAddressLine2} error={formik.errors.correspondenceAddressLine2} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{/* prettier-ignore */}
											<AppSelectInput required type="text" name="state" label="State" placeholder="e.g. Wilayah Persekutuan KL" disabled={formik.isSubmitting} loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={onHandleSelectField} />

											{/* prettier-ignore */}
											<AppSelectInput required type="text" name="city" label="City" placeholder="Please Select" disabled={formik.isSubmitting} loadOptions={getCitiesLoadOptions} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={onHandleSelectField} />

											{/* prettier-ignore */}
											<AppSelectInput required type="text" name="postcode" label="Postcode" placeholder="e.g. 57000" disabled={formik.isSubmitting} loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={onHandleSelectField} />

											{/* prettier-ignore */}
											<AppSelectInput required type="text" name="occupation" label="Occupation" placeholder="Please Select" loadOptions={getOccupationListing} value={formik.values.occupation} error={formik.errors?.occupation} touched={formik.touched?.occupation} onChange={formik.handleChange} />

											{/* prettier-ignore */}
											<AppInput required type="number" maxLength={2} name="experience" label="Driving Experience (Years)" placeholder="e.g. 10" value={formik.values.experience} error={formik.errors?.experience} touched={formik.touched?.experience} onChange={formik.handleChange} />
										</div>
									</div>
								</div>
							</AppCardContainer>

							<div className="applicant__button-container">
								<AppButton outline type="button" label="Back" disabled={formik.isSubmitting} onClick={onHandleNavigateQuotation} />
								<AppButton type="submit" label="Next" disabled={formik.isSubmitting} />
							</div>
						</div>
					)}
				</form>

				{!formik.isSubmitting && messageType && (
					<div className="applicant__container-box">
						<div className="applicant__container-body">
							<AppCardStatus messageType={messageType} setMessageType={setMessageType} />
						</div>
					</div>
				)}

				{formik.isSubmitting && (
					<div className="applicant__container-box">
						<div className="applicant__container-body">
							<AppLoading isLoading={formik.isSubmitting} text="Processing page during quotation finalisation page - We're currently finalising your quotation." />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PageApplicant;
