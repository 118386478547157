import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getMaritalStatusListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { lookupHdrId: "MOTOR_MARITAL_STATUS", sort: "seq,asc" };
		const res = await api.get.general.list(params);
		const content = res.map(({ lookupTitle, lookupValue, ...o }) => ({ label: lookupTitle, value: lookupValue, ...o }));
		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getMaritalStatusListing;
