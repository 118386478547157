import React, { memo } from "react";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";

const AppCardContainer = (props) => {
	return (
		<div className="app-card-container">
			<div className="main">
				<div className="main__header">
					<div className="main__wrapper">
						<p className="main__title">{props.title}</p>
						<p className="main__description">{props.description}</p>
					</div>

					<div className="main__wrapper">
						{props.onHandleSwitch && <Switch onChange={props.onHandleSwitch} checked={props.isChecked} />}
						{props.subTitle && <p className="main__description">{props.subTitle}</p>}
						{props.subDescription && <p className="main__description">{props.subDescription}</p>}
					</div>
				</div>

				<div className="main__divider" />

				<div className="main__body">
					<div className="main__box">{props.children}</div>
				</div>
			</div>
		</div>
	);
};

AppCardContainer.propTypes = {
	onHandleSwitch: PropTypes.func,
	subTitle: PropTypes.string,
	children: PropTypes.node.isRequired,
	subDescription: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
};

export default memo(AppCardContainer);
