import React, { memo, useMemo, useState, useCallback, Fragment } from "react";
import { Menu, MenuItem, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

import classNames from "common/class-names";
import { formatCurrency } from "common/format-currency-pattern";
import AppButton from "components/app-button";
import AppCloseIcon from "components/icons/app-close-icon";

const AppCard = (props) => {
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(null);
	const className = useMemo(() => {
		return classNames({
			"main-content__radio": true,
			"main-content__radio--active": props.checked,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.checked]);

	const onHandleChange = () => {
		props.onChange(props.data.id);
	};

	const onhandleCloseMenu = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleShowAMenu = useCallback(() => {
		const target = document.querySelector(".main__box");
		setAnchorEl(target);
	}, []);

	const onhandleCloseDownloadMenu = useCallback(() => {
		setDownloadMenuAnchor(null);
	}, [setDownloadMenuAnchor]);

	//prettier-ignore
	const onHandleShowDownloadMenu = useCallback((event) => {
		setDownloadMenuAnchor(event.target);
	},[setDownloadMenuAnchor]);

	return (
		<div className="app-card">
			<div className="main-content">
				<div className="main-content__box">
					<button className={className} type="button" disabled={props.disabled} onClick={onHandleChange} />
				</div>

				<div className="main-content__container">
					<div className="header">
						<div className="header__company">
							<div className="header__image">
								<img src={props.data?.providerDetails?.logo} alt="profile" />
							</div>

							<p className="header__title">{props.data?.insurer}</p>
						</div>

						<div className="header__body">
							<div className="header__text">Premium Amount</div>

							<div className="header__title">{`RM${formatCurrency(props.data?.grossPremium)}`}</div>
						</div>
					</div>

					<div className="body">
						<div className="body__content">
							<div className="body__wrapper">
								<div className="body__text">Min</div>

								<div className="body__text">{`RM${formatCurrency(props.data?.minimumMarketValue)}`}</div>
							</div>

							{props.data?.maximumMarketValue && (
								<Fragment>
									<div className="body__wrapper">
										<div className="body__text">-</div>
									</div>

									<div className="body__wrapper">
										<div className="body__text">Max</div>

										<div className="body__text">{`RM${formatCurrency(props.data?.maximumMarketValue)}`}</div>
									</div>
								</Fragment>
							)}
						</div>

						<div className="body__sum-insured">
							<div className="body__text">Sum Insured</div>

							<div className="body__title">{`RM${formatCurrency(props.data?.sumInsured)}`}</div>
						</div>
					</div>

					<div className="main-content__divider"></div>

					<div className="footer">
						{/* prettier-ignore */}
						<AppButton type="button" label="Coverage details" onClick={onHandleShowAMenu} />
					</div>
				</div>

				{/* prettier-ignore */}
				<Menu classes={{ root: "app-detail-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "top", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
					<div className="detail-menu">
						<div className="detail-menu__container" id={props.name}>
							<div className="header">
								<div className="header__company">
									<div className="header__image">
										<img src={props.data?.providerDetails?.logo} alt="profile" />
									</div>

									<p className="header__title">{props.data?.insurer}</p>
								</div>

								<button type="button" className="detail-menu__remove" onClick={onhandleCloseMenu}>
									<AppCloseIcon color="#666666" />
								</button>
							</div>

							<div className="detail-menu__divider" />

							<div className="body">
								<p className="body__title">{props.data?.providerDetails?.title}</p>
								
								<ul className="body__list">
									{props.data?.providerDetails?.description.split("/n").map((o, i) => {
										return (
											<li className="body__item" key={i}>
												<p className="body__text">{o}</p>
											</li>
										)
									})}
								</ul>
									
							</div>

							<div className="detail-menu__divider" />

							<div className="footer">
								<div>
									<div className="footer__content">
										<div className="footer__upper-content" >
											<div className="footer__wrapper">
												<div className="footer__text">Min</div>

												<div className="footer__text">{`RM${formatCurrency(props.data?.minimumMarketValue)}`}</div>
											</div>
											
											{props.data?.maximumMarketValue && (
												<Fragment>
													<div className="footer__wrapper">
														<div className="footer__title">-</div>
													</div>

													<div className="footer__wrapper">
														<div className="footer__text">Max</div>

														<div className="footer__text">{`RM${formatCurrency(props.data?.maximumMarketValue)}`}</div>
													</div>
												</Fragment>
											)}
										</div>

										<div className="footer__sum-content">
											<div className="footer__wrapper">
												<div className="footer__text">Sum Insured</div>

												<div className="footer__title">{`RM${formatCurrency(props.data?.sumInsured)}`}</div>
											</div>
										</div>
									</div>
								</div>
								
								<div>
									<div className="footer__premium-amount">
										<div className="footer__text">Premium Amount</div>

										<div className="footer__sub-title">{`RM${formatCurrency(props.data?.grossPremium)}`}</div>
									</div>
								</div>
							</div>
						</div>

						<div className="detail-menu__button-container">
							<AppButton type="button" label="Download" onClick={onHandleShowDownloadMenu}/>
						</div>

						<Menu classes={{ root: "download-menu" }} anchorEl={downloadMenuAnchor} open={!!downloadMenuAnchor} onClose={onhandleCloseDownloadMenu}  anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} transformOrigin={{vertical: 'top' ,horizontal: 'center',}}>
							<MenuItem>
								<a className="download-menu__text" href={props.data?.providerDetails?.pdsLink} target="_blank" rel="noreferrer">PDS</a>
							</MenuItem>

							<MenuItem>
								<a className="download-menu__text" href={props.data?.providerDetails?.policyWordingLink} target="_blank" rel="noreferrer">Policy Wording</a>
							</MenuItem>

							<MenuItem>
								<a className="download-menu__text" href={props.data?.providerDetails?.compulsoryExcessLink} target="_blank" rel="noreferrer">Compulsory Excess</a>
							</MenuItem>
						</Menu>
					</div>
				</Menu>
			</div>

			<FormHelperText className="main-content__error">{errorMessage}</FormHelperText>
		</div>
	);
};

AppCard.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.string,
		insurer: PropTypes.string.isRequired,
		grossPremium: PropTypes.string.isRequired,
		minimumMarketValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		maximumMarketValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		sumInsured: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		providerDetails: PropTypes.shape({
			title: PropTypes.string.isRequired,
			logo: PropTypes.string.isRequired,
			pdsLink: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			policyWordingLink: PropTypes.string.isRequired,
			compulsoryExcessLink: PropTypes.string.isRequired,
		}),
	}),
	checked: PropTypes.bool,
	touched: PropTypes.bool,
	error: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
};

export default memo(AppCard);
