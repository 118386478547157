import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";

import classNames from "common/class-names";

const AppMarketCard = (props) => {
	const selected = useMemo(() => props.value, [props.value]);
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);

	const className = useMemo(() => {
		return classNames({
			"market-card__radio": true,
			"market-card__radio--active": props.checked,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.checked]);

	return (
		<div className="app-market-card">
			<div className="market-card">
				<div className="market-card__box">
					<button className={className} type="button" disabled={props.disabled} name={props.name} checked={selected} onClick={props.onChange} />
				</div>
				<div className="market-card__wrapper">
					<div className="market-card__container">
						<div className="body">
							<div className="body__wrapper">
								<p className="body__text">{props.label}</p>
								{props.inputValue && <p className="body__value">{`RM${props.inputValue}`}</p>}
								{!props.inputValue && <p className="body__disabled">Not available</p>}
							</div>
						</div>
					</div>
					<p className="market-card__description">{props.description}</p>
				</div>
			</div>
			<FormHelperText className="market-card__error">{errorMessage}</FormHelperText>
		</div>
	);
};

AppMarketCard.propTypes = {
	value: PropTypes.bool,
	error: PropTypes.string,
	touched: PropTypes.bool,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
	inputValue: PropTypes.string,
	description: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

export default memo(AppMarketCard);
