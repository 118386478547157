import React, { memo, useState, useCallback, useImperativeHandle, forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppButton from "components/app-button";

const AppExpiredModal = (props, ref) => {
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);
	const motor = useSelector((state) => state.motor);
	const referralCode = useMemo(() => motor?.referralCode, [motor?.referralCode]);

	const onHandleShow = useCallback(async () => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		navigate(pathnames.home + `?myReferralCode=${referralCode}`);
	}, [navigate, referralCode]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal keepMounted aria-labelledby="expired-modal__title" aria-describedby="expired-modal__text" open={visible} closeAfterTransition onClose={onHandleDismiss}>
			<Fade in={visible}>
				<div className="app-expired-modal">
					<div className="expired-modal">
						<div className="expired-modal__container">
							<p className="expired-modal__title">Your quotation had expired!</p>
							<p className="expired-modal__text">You are required to restart your journey.</p>

							<AppButton type="button" label="Ok" onClick={onHandleDismiss} />
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default memo(forwardRef(AppExpiredModal));

AppExpiredModal.propTypes = {
	ref: PropTypes.object,
};
