import React, { memo, useLayoutEffect, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";

import AppHeader from "components/app-header";
import { AxiosContext } from "contexts/with-interceptor-provider";

const AppLayout = ({ children, ...props }) => {
	const location = useLocation();
	const context = useContext(AxiosContext);

	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0);
	}, [location.pathname]);

	const contextProps = useMemo(() => ({ onHandleCancelRequest: context.onHandleCancelRequest }), [context]);

	return (
		<div className="app-layout">
			<main className="main">
				<div id="layout-container" className="main__container">
					<AppHeader />

					<div className="main__page">{React.cloneElement(children, { ...props, ...contextProps })}</div>

					<footer className="main__copyright">© {new Date().getFullYear()} easycover.my. All Rights Reserved.</footer>
				</div>
			</main>
		</div>
	);
};

export default memo(AppLayout);
