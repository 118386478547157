import React, { useMemo, memo, useState, useCallback } from "react";

import classNames from "common/class-names";
import AppYears from "./app-years";
import AppMonths from "./app-months";
import AppWeekDays from "./app-weekdays";
import AppChevronIcon from "components/icons/app-chevron-icon";
import { CALENDAR_FORMAT, getDates, getMonths, getISOString } from "common/calendar";

const AppCalendar = (props) => {
	const today = useMemo(() => getISOString(new Date(new Date().setHours(0, 0, 0, 0))), []);
	const defaultDate = useMemo(() => (props.value ? getISOString(props.value) : today), [props.value, today]);
	const [calendarDate, setCalendarDate] = useState(defaultDate);
	const [date, setDate] = useState(defaultDate);
	const [yearsVisible, setYearsVisible] = useState(false);
	const [monthsVisible, setMonthsVisible] = useState(false);
	const calendarYear = useMemo(() => new Date(calendarDate).getFullYear(), [calendarDate]);
	const dates = useMemo(() => getDates(calendarDate, props.maxDate, props.minDate), [calendarDate, props.maxDate, props.minDate]);
	const calendarMonth = useMemo(() => getMonths(CALENDAR_FORMAT.MONTH_FORMAT).find((o) => o.value === new Date(calendarDate).getMonth() + 1).month, [calendarDate]);
	const selectedDate = useMemo(() => dates.find((o) => o.iso === date)?.iso, [date, dates]);
	const maxDate = useMemo(() => (props.maxDate ? getISOString(props.maxDate) : null), [props.maxDate]);
	const minDate = useMemo(() => (props.minDate ? getISOString(props.minDate) : null), [props.minDate]);
	const onChange = useMemo(() => props.onHandleSelectDate, [props]);

	const disabled = useMemo(() => {
		const nextDate = new Date(calendarDate);
		const nextDateYear = nextDate.getFullYear();
		const nextDateMonth = nextDate.getMonth();
		const isJanuary = nextDateMonth === 0;
		const isDecember = nextDateMonth === 11;
		const isMinYear = nextDateYear === (minDate ? new Date(minDate).getFullYear() : 1900);
		const isMaxYear = nextDateYear === (maxDate ? new Date(maxDate).getFullYear() : 2099);

		return { next: isMaxYear && isDecember, prev: isJanuary && isMinYear };
	}, [calendarDate, maxDate, minDate]);

	const onHandleDisplayMonths = () => {
		setYearsVisible(false);
		setMonthsVisible((prev) => !prev);
	};

	const onHandleDisplayYears = () => {
		setMonthsVisible(false);
		setYearsVisible((prev) => !prev);
	};

	//prettier-ignore
	const onHandleChangeMonth = useCallback((month) => {
		const d = new Date(calendarDate).setMonth(month - 1);
		setCalendarDate(d);
		onHandleDisplayMonths();
	}, [calendarDate]);

	//prettier-ignore
	const onHandleChangeYear = useCallback((year) => {
		const d = new Date(calendarDate).setFullYear(year);
		setCalendarDate(d);
		onHandleDisplayYears();
	}, [calendarDate]);

	//prettier-ignore
	const onHandleChangeDate = useCallback((v, isPreviousMonth, isNextMonth) => {
		if(isPreviousMonth || isNextMonth) {
			const current = new Date(v);
			const d = current.setMonth(current.getMonth());
			setCalendarDate(d);
		}
		setDate(v);
		onChange(v);
	}, [onChange]);

	//prettier-ignore
	const onHandleSlideCalendar = useCallback((event) => {
		const nextDate = new Date(calendarDate);
		const type = event.target.getAttribute("data-slide");

		switch (type) {
			case "prev":
				nextDate.setMonth(nextDate.getMonth() - 1);
				break;
			case "next":
				nextDate.setMonth(nextDate.getMonth() + 1);
			break;
			default:
				break;
		}
		setCalendarDate(nextDate);
	}, [calendarDate]);

	const Cell = useCallback(() => {
		if (monthsVisible || yearsVisible) return null;

		return (
			<ul className="dates">
				{dates.map((o) => {
					const isToday = o.isToday;
					const disabled = o.isDisabled;
					const isNextMonth = o.isNextMonth;
					const isActive = o.iso === selectedDate;
					const isPreviousMonth = o.isPreviousMonth;

					const itemClassNames = classNames({
						dates__item: true,
						"dates__item--today": isToday,
						"dates__item--next": isNextMonth,
						"dates__item--disabled": disabled,
						"dates__item--previous": isPreviousMonth,
						"dates__item--active": isActive && !isPreviousMonth && !isNextMonth,
					});

					return (
						<li className={itemClassNames} key={o.iso} onClick={() => onHandleChangeDate(o.iso, isPreviousMonth, isNextMonth)}>
							<div className="dates__day">{o.date}</div>
						</li>
					);
				})}
			</ul>
		);
	}, [dates, selectedDate, yearsVisible, monthsVisible, onHandleChangeDate]);

	return (
		<div className="app-calendar">
			<div className="calendar">
				<div className="header">
					<button className="header__prev" data-slide="prev" disabled={disabled.prev} onClick={onHandleSlideCalendar}>
						<AppChevronIcon />
					</button>
					<div className="header__wrapper">
						{/* prettier-ignore */}
						<p className="header__month" onClick={onHandleDisplayMonths}>{calendarMonth}</p>
						{/* prettier-ignore */}
						<p className="header__year" onClick={onHandleDisplayYears}>{calendarYear}</p>
					</div>
					<button className="header__next" data-slide="next" disabled={disabled.next} onClick={onHandleSlideCalendar}>
						<AppChevronIcon />
					</button>
				</div>

				{!yearsVisible && !monthsVisible && <AppWeekDays />}

				{yearsVisible && <AppYears value={date} maxDate={maxDate} minDate={minDate} onChange={onHandleChangeYear} />}

				{monthsVisible && <AppMonths value={date} onChange={onHandleChangeMonth} />}

				<Cell />
			</div>
		</div>
	);
};

export default memo(AppCalendar);
