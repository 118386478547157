import { isDateBeforeToday } from "common/calendar";

const getIdDobYear = (value) => {
	const nricLast4Digit = value.slice(10, 14);

	const years90 = parseInt(nricLast4Digit[0]) >= 5;

	let dobYear = parseInt(value.slice(0, 2));

	if (!years90) {
		dobYear = 2000 + dobYear;
	} else {
		dobYear = 1900 + dobYear;
	}
	return dobYear;
};

export const isValidDate = (dateObj) => {
	return new Date(dateObj).toString() !== "Invalid Date";
};

export const getDateOfBirthNRIC = (value) => {
	let nextDob = "";

	if (value && value.length >= 14) {
		const dob = value.slice(0, 6);
		const dobMonth = dob.slice(2, 4);
		const dobDay = dob.slice(4, 6);
		const dobYear = getIdDobYear(value);
		const d = new Date(`${dobYear}-${dobMonth}-${dobDay}`);

		const isValid = isValidDate(d);

		if (isValid) nextDob = d;
	}

	return nextDob;
};

export const validateNRIC = (value) => {
	let isValid = false;

	if (value?.length >= 14) {
		const dob = value.slice(0, 6);
		const dobMonth = dob.slice(2, 4);
		const dobDay = dob.slice(4, 6);
		const dobYear = getIdDobYear(value);

		const d = new Date(`${dobYear}-${dobMonth}-${dobDay}`);

		isValid = isValidDate(d) && isDateBeforeToday(d, new Date());
	}

	return isValid;
};